.applicationsSidebar {
  display: flex;
  flex-shrink: 0;
  z-index: 200;
  // width: 250px;
  // transition: width 0.3s ease;
  // overflow: hidden;
  &.collapsed{
    // width: 60px;
    // overflow: hidden;
    .applicationRootSidebar{
      width: 60px;
      .mainNavigationList{
        .navItem{
          .rootNavText{
            display: none;
          }
        }
      }
    }
  }
  .applicationRootSidebar {
    width: 14rem;
    background-color: rgb(245, 245, 250);
    border-right-width: 1px;
    border-color: rgba(214, 214, 231, 0.5);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    transition: width 0.3s ease;
    @media (min-width: 768px) {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
    }

    .applicationSidebarScroll {
      height: 100%;
      margin-right: -1px;
      overflow: hidden;
      position: relative;

      &:after {
        height: 48px;
        left: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
        content: "";
        background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
        bottom: 0;
      }
    }

    .scrollIndicatorContent {
      height: 100%;
      overflow-y: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-bottom: 1.75rem;
      scrollbar-width: none;
      -ms-overflow-style: none;

      .sidebarHeader {
        margin-bottom: 1rem;
        display: flex;
        height: 72px;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(214, 214, 231, 0.5);
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }

      .navigationWrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        .navItem {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          @media (min-width: 768px) {
            font-size: 0.875rem;
          }
          display: flex;
          height: 2rem;
          width: 100%;
          min-width: 2rem;
          align-items: center;
          border-radius: 3px;
          border-width: 1px;
          border-color: transparent;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          &.active {
            --cb-ring-shadow: 0 0 #0000;
            --cb-ring-offset-shadow: 0 0 #0000;
            --cb-shadow: 0px 0px 0px 1px rgba(35, 38, 59, 0.05),
              0px 1px 3px 0px rgba(35, 38, 59, 0.15);
            --cb-shadow-colored: 0px 0px 0px 1px var(--cb-shadow-color),
              0px 1px 3px 0px var(--cb-shadow-color);
            box-shadow: var(--cb-ring-offset-shadow, 0 0 #0000),
              var(--cb-ring-shadow, 0 0 #0000), var(--cb-shadow);
            --cb-gradient-to: #fcfcfd;
            --cb-gradient-from: #fff;
            --cb-gradient-to: hsla(0, 0%, 100%, 0);
            --cb-gradient-stops: var(--cb-gradient-from), var(--cb-gradient-to);
            background-image: linear-gradient(
              to bottom,
              var(--cb-gradient-stops)
            );

            svg {
              --cb-text-opacity: 1;
              color: rgb(41 57 78 / var(--cb-text-opacity));
            }
            .rootNavText {
              --cb-text-opacity: 1;
              color: rgb(41 57 78 / var(--cb-text-opacity));
              font-weight: 700;
            }
          }
          svg {
            --cb-text-opacity: 1;
            color: rgb(41 57 78 / var(--cb-text-opacity));
          }
          .rootNavText {
            --cb-space-x-reverse: 0;
            --cb-text-opacity: 1;
            color: rgb(41 57 78 / var(--cb-text-opacity));
            margin-left: calc(0.75rem * (1 - var(--cb-space-x-reverse)));
            margin-right: calc(0.75rem * var(--cb-space-x-reverse));
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1;
          }
        }
      }
    }
  }
}

nav.rootSidebarBottom {
  --cb-space-y-reverse: 0;
  margin-bottom: calc(2rem * var(--cb-space-y-reverse));
  margin-top: calc(2rem * (1 - var(--cb-space-y-reverse)));
}

// .applicationsSidebar:not(.collapsed) .sidebarHeader img {
//   width: 100%;
//   padding: 10px;
//   transition: opacity 0.3s ease;
// }

// .applicationsSidebar.collapsed .sidebarHeader img {
//   width: 50px;
//   height: 50px;
//   transition: opacity 0.3s ease;
//   right: 10px;
//   position: relative;
// }

.searchItem.collapsedList.active {
  box-shadow: none;
  background-image: none;
}
.rootNavText {
  margin-left: 10px;
  transition: opacity 0.3s ease;
}

.applicationsSidebar.collapsed .rootNavText {
  opacity: 0;
  visibility: hidden;
}

.navItem .menuSvg svg {
  transition: stroke 0.1s ease;
}

// .applicationsSidebar {
//   display: flex;
//   flex-shrink: 0;
//   z-index: 200;
//   width: 250px;
//   transition: width 0.3s ease;
//   overflow: hidden;
//   .applicationRootSidebar {
//     width: 14rem;
//     background-color: rgb(245, 245, 250);
//     border-right-width: 1px;
//     border-color: rgba(214, 214, 231, 0.5);
//     font-size: 1rem;
//     font-weight: 400;
//     line-height: 1.5rem;

//     @media (min-width: 768px) {
//       font-size: 0.875rem;
//       font-weight: 400;
//       line-height: 1.25rem;
//     }

//     .applicationSidebarScroll {
//       height: 100%;
//       margin-right: -1px;
//       overflow: hidden;
//       position: relative;

//       &:after {
//         height: 48px;
//         left: 0;
//         pointer-events: none;
//         position: absolute;
//         width: 100%;
//         content: "";
//         background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
//         bottom: 0;
//       }
//     }

//     .scrollIndicatorContent {
//       height: 100%;
//       overflow-y: auto;
//       position: relative;
//       display: flex;
//       flex-direction: column;
//       overflow-x: hidden;
//       padding-left: 0.75rem;
//       padding-right: 0.75rem;
//       padding-bottom: 1.75rem;
//       scrollbar-width: none;
//       -ms-overflow-style: none;

//       .sidebarHeader {
//         margin-bottom: 1rem;
//         display: flex;
//         height: 72px;
//         flex-shrink: 0;
//         align-items: center;
//         justify-content: space-between;
//         border-bottom: 1px solid rgba(214, 214, 231, 0.5);
//         padding-left: 0.25rem;
//         padding-right: 0.25rem;
//       }

//       .navigationWrapper {
//         display: flex;
//         height: 100%;
//         flex-direction: column;
//         justify-content: space-between;
//         .searchItem {
//           font-size: 1rem;
//           font-weight: 400;
//           line-height: 1.5rem;
//           @media (min-width: 768px) {
//             font-size: 0.875rem;
//           }
//           display: flex;
//           height: 2rem;
//           width: 100%;
//           min-width: 2rem;
//           align-items: center;
//           border-radius: 3px;
//           border-width: 1px;
//           border-color: transparent;
//           padding-left: 0.5rem;
//           padding-right: 0.5rem;
//           &.active {
//             box-shadow: none;

//             svg {
//               --cb-text-opacity: 1;
//               stroke: blue;
//             }
//             .rootNavText {
//               --cb-text-opacity: 1;
//               color: rgb(41 57 78 / var(--cb-text-opacity));
//               font-weight: 700;
//             }
//           }
//           svg {
//             --cb-text-opacity: 1;
//             color: rgb(41 57 78 / var(--cb-text-opacity));
//           }
//           .rootNavText {
//             --cb-space-x-reverse: 0;
//             --cb-text-opacity: 1;
//             color: rgb(41 57 78 / var(--cb-text-opacity));
//             margin-left: calc(0.75rem * (1 - var(--cb-space-x-reverse)));
//             margin-right: calc(0.75rem * var(--cb-space-x-reverse));
//             overflow: hidden;
//             text-overflow: ellipsis;
//             white-space: nowrap;
//             line-height: 1;
//           }
//         }
//       }
//     }
//   }
// }
