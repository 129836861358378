.contentHeaderWrapper {
  display: flex;
  z-index: 200;
  align-items: center;
  background-color: rgb(252 252 253 / 1);
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  .contentHeaderInner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1632px;
    display: flex;
    align-items: center;
    flex: 1 1 0%;
    height: 72px;
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: rgba(214, 214, 231, 0.5);
    justify-content: space-between;

    .contentHeaderLeftWrap {
      // overflow: hidden;
      .storeNameLabel {
        flex-direction: column;
        display: flex;
        min-width: 7rem;
        align-items: flex-start;

        // overflow: hidden;
        .storeNameLabelHeader {
          font-size: 0.8125rem;
          font-weight: 400;
          line-height: 1.25rem;

          @media (min-width: 768px) {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1rem;
            color: var(--primary-color);
          }
        }

        .storeNameContentBtn {
          display: inline-flex;
          height: 1.5rem;
          width: 100%;
          max-width: 250px;
          align-items: center;
          border-radius: 3px;
          padding-left: 0.25rem;
          padding-right: 2px;
          --cb-space-y-reverse: 0;
          margin-bottom: calc(0.25rem * var(--cb-space-y-reverse));
          margin-top: calc(0.25rem * (1 - var(--cb-space-y-reverse)));
          text-decoration: none;
          color: inherit;

          .storeNameContentWrap {
            color: var(--primary-color);
            text-decoration: none;
            font-weight: 600;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1 1 0%;
            font-size: 1rem;
            line-height: 1.5rem;

            @media (min-width: 768px) {
              font-size: 0.875rem;
            }
          }
        }
      }
    }

    .contentHeaderRightWrap {
      --cb-space-x-reverse: 0;
      margin-left: calc(1rem * (1 - var(--cb-space-x-reverse)));
      margin-right: calc(1rem * var(--cb-space-x-reverse));
      display: flex;
      align-items: center;

      .userFlex {
        display: flex;
        align-items: center;
        gap: 5px;

        svg {
          color: rgb(119, 122, 175);
        }
      }
    }
  }
}

.userDropDownMenu {
  &.websiteDropdown {
    .dropdownSubMenu {
      padding: 8px 12px;
      cursor: pointer;
      .websiteDropdownLeft {
        max-width: 220px;
        p {
          margin-bottom: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .websiteDropdownRight {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
