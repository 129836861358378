//@import "common.scss";
@import "sidebar.scss";
@import "header.scss";

body{
	--primary-color: #005EFF !important;
}

.applicationContentWrap {
	display: flex;
	width: 100vw;
	height: 100vh;
	.contentMainContainer{
		display: flex;
		height: 100%;
		flex: 1 1 0%;
		flex-direction: column;
		overflow-y: scroll;
		.pageContentWrapper{
			position: relative;
			flex: 1 1 0%;
			padding: 1.5rem 2rem 2rem;
    		position: relative;
			.pageContentInnerWrapper{
				max-width: 1623px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	
}

.cta{
	font-size: 16px;
	font-weight: 500;
	height: 55px;
	background: radial-gradient(163.33% 163.33% at 50% 100%, hsla(218, 100%, 50%, 0.45) 0, transparent 100%, hsla(218, 100%, 50%, 0) 100%), var(--primary-color) !important;
	background-blend-mode: overlay, normal;
	border: 1px solid var(--btn-border-color);
	box-shadow: inset 0 0 0 1px var(--btn-outline-color), 0 3px 4px var(--btn-shadow-color);
	color: var(--always-white);
	&:not(.autoWidth){
		width: 100%;
	}
	&:focus,
	&:focus:active,
	&:active,
	&:hover{
		background-color: #29394E !important;
		box-shadow: inset 0 0 0 1px var(--btn-yellow-outline-color), 0 3px 4px var(--btn-yellow-shadow-color);
	}
}

.dotBanner {
	background-image: url("./../images/dot-banner.svg");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-size: auto 100%;	
}

.copyTextInputWrap{
	label{
		margin-bottom: 0;
	}
}