//@import 'common.scss';

h1 {
	color: #29394e;
	font-weight: bold;
}

h2 {
	color: #29394e;
	font-size: 1.5rem;
	font-weight: bold;
}

h3 {
	color: #29394e;
	font-size: 1.2rem;
	font-weight: bold;
}

.getStartedHeader {
	padding: 24px 64px;
	border-bottom: 1px solid rgb(214, 214, 231);
	color: #29394e;
}

.getStartedContentWrapper {
	padding: 24px 64px;
	background-color: #FCFCFD;
}

.platformWrapper {
	padding: 20px 20px;
	border: 1px solid #29394e;
	border-radius: 15px;
	text-align: center;
	cursor: pointer;

	&.selectedPlatform,
	&:hover {
		border-color: var(--primary-color);
		-webkit-box-shadow: 4px 3px 2px 0px rgb(0 94 255 / 50%);
		-moz-box-shadow: 4px 3px 2px 0px rgb(0 94 255 / 50%);
		box-shadow: 4px 3px 2px 0px rgb(0 94 255 / 50%);
	}
}

.storeInformationWrapper {
	box-shadow: 0 0 0 1px rgba(35, 38, 59, .05), 0 1px 3px 0 rgba(35, 38, 59, .15);
	padding: 2.25rem;
}

.shopifyInstructionManual {
	box-shadow: 0 0 0 1px rgba(35, 38, 59, .05), 0 1px 3px 0 rgba(35, 38, 59, .15);
	padding: 2.25rem;

	.installMaunalText {
		margin-top: 8px;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		gap: 4px;

		a {
			display: flex;
			align-items: center;
			color: #003dff;
			gap: 8px;
		}
	}

	img {
		box-shadow: 0 0 0 1px rgba(35, 38, 59, .05), 0 1px 3px 0 rgba(35, 38, 59, .15);
	}
}

.cta {
	font-size: 16px;
	font-weight: 500;
	height: 55px;
	background: radial-gradient(163.33% 163.33% at 50% 100%, hsla(0, 0%, 100%, 0.45) 0, transparent 100%, hsla(0, 0%, 100%, 0) 100%), var(--primary-color) !important;
	background-blend-mode: overlay, normal;
	border: 1px solid var(--btn-border-color);
	box-shadow: inset 0 0 0 1px var(--btn-outline-color), 0 3px 4px var(--btn-shadow-color);
	color: var(--always-white);

	&:not(.autoWidth) {
		width: 100%;
	}

	&:focus,
	&:focus:active,
	&:active,
	&:hover {
		background-color: rgb(255, 132, 52) !important;
		box-shadow: inset 0 0 0 1px var(--btn-yellow-outline-color), 0 3px 4px var(--btn-yellow-shadow-color);
	}
}

.onboardingFormWrapper {
	width: 100%;
	max-width: 460px;
	margin: auto;

	.input-group {
		margin-bottom: 25px;

		.error-message {
			font-size: 11px;
			position: absolute;
			color: red;
			top: 100%;
		}
	}
}

/* Bot configuration wrapper */
.bot-configuration-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 81%;
	background-color: inherit;
	margin-top: 10px;
}

.outer-card {
	background-color: var(--white);
	border-radius: 8px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	max-width: 724px;
	width: 100%;
	height: 100%;
	text-align: center;
	margin-top: 30px;

	.header {
		font-size: 18px;
		margin: 10px;
		color: #333;
	}

	.inner-card {
		background-color: var(--white);
		border-radius: 8px;
		height: 70%;
		width: 85%;
		padding: 20px;
		// box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
		margin: 0 auto;
		text-align: center;
		position: relative;
		/* Make this relative to allow absolute positioning of buttons */
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		/* Content at the top */
	}

	/* Styling the steps */
	.steps {
		display: flex;
		justify-content: space-around;
		align-items: center;
		gap: 20px;
		margin: 0;
		padding: 0;

		.step {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0;
			padding: 0;

			.text {
				color: #29394e;
				margin: 0;
				padding: 3px;
				background-color: transparent;
				border: none;
				cursor: pointer;
				text-align: center;
				transition: color 0.3s ease;
			}

			.underLine {
				height: 2px;
				width: 100%;
				background-color: rgba(118, 118, 118, 1);
				transition: background-color 0.3s ease;
			}

			&.active .underLine {
				background-color: #29394e;
			}

			.text:hover {
				background-color: var(--white);
				color: #29394e;
			}

			&.active .text {
				color: #29394e;
				font-weight: bold;
			}
		}
	}

	/* Navigation buttons at the bottom corners */
	.navigation-buttons {
		display: flex;
		justify-content: space-between;
		/* Space out the buttons (Prev left, Next right) */
		width: 100%;
		/* Ensures full width for proper positioning */
		padding: 10px 0;
		/* Add some space at the top and bottom */
	}

	.prev-button,
	.next-button {
		background-color: transparent;
		/* No background */
		border: none;
		/* No border */
		font-size: 16px;
		color: initial;
		/* Text color */
		cursor: pointer;
		padding: 10px;
		font-weight: normal;
		/* No bold on buttons */
		box-shadow: none;
		display: flex;
		align-items: center;
		width: 100%;
		margin: 0;
	}

	/* Next button */
	.next-button {
		justify-content: flex-end;
	}

	.arrow {
		width: 12px;
		height: 12px;
	}

	/* Disabled state for buttons */
	.prev-button:disabled,
	.next-button:disabled {
		cursor: not-allowed;
		color: #bdbdbd;
		/* Disabled text color */
	}

	/* Disabled button style */
	.disabled {
		cursor: not-allowed;
		color: #29394e !important;
		background: #bdbdbd !important;
		box-shadow: none !important;
		border: none !important;
		/* Disabled text color */
	}

	.disabled:hover {
		background: #bdbdbd !important;
		/* Disabled text color */

	}

	.bottomText {
		width: 50%;
		font-size: small;
		font-weight: bolder;
		margin: 8px auto;
	}

	/* Modal Overlay */
	.modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		/* Semi-transparent black background */
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
		/* Ensure modal is above other content */
	}

	/* Modal Content */
	.modal-content {
		background-color: #fff;
		padding: 20px;
		border-radius: 8px;
		width: 400px;
		text-align: center;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	}

	.modal-content .image img {
		width: 20px;
		height: 20px;
		margin-left: 0;
	}

	.model-content-button {
		display: flex;
		align-items: flex-start;
	}

	.model-content-button button {
		background-color: #005EFF !important;
		color: #fff;
		border: none;
		padding: 10px 15px;
		cursor: pointer;
		border-radius: 5px;
		margin: 0 30px;
	}
}

.model-image {
	display: flex;
	gap: 10px;

}

.text-dialog {
	text-align: start;
}

.model-image .image img {
	width: 20px;
}

/* Rotate the right arrow to the left for the Prev button */
.prev-button .prev-arrow {
	transform: rotate(180deg);
	width: 12px;
	height: 12px;
	margin-right: 8px;
}

.outer-card .model-content-button button:hover {
	background-color: #29394E !important;

}

.testconnection button:hover {
	background-color: #29394E !important;
}

.cdn-wrapper {
	display: flex;
	flex-direction: column;
	background-color: inherit;
	border-radius: 8px;
	width: 100%;
	max-width: 600px;
	margin: 5px auto;
	font-family: "Roboto", arial, sans-serif;
	font-weight: 500;
}

.cdn-header {
	font-size: 16px;
	font-weight: bold;
	color: #333;
	margin-bottom: 10px;
}

.cdn-text {
	font-size: 16px;
	margin-bottom: 5px;
	.bold-text {
		font-weight: bold;
		// color: #4CAF50; /* Green color for emphasis */
	}
}

/* Styling for each list item */
li {
	font-size: 16px;
	margin-bottom: 10px;
	/* Space between list items */

	&:last-child {
		margin-bottom: 0;
		/* Remove margin on the last list item */
	}

	/* Optional: Add some padding or background for better spacing and readability */
	padding: 5px 0;
}

li .bold-text {
	font-weight: bold;
	color: #4CAF50;
}

/* Styling for the strong text with the icon (Cloudflare_Worker.js) */
strong {
	font-weight: normal;
	/* Remove default bold for strong text */
	font-size: 14px;
	color: #333;
}

// img {
//   width: 20px;
//   height: 20px;
//   margin-left: 5px;
// }

/* Remove background, no hover effects, and style the button as plain text */
.download-btn {
	background: none;
	/* Remove any background */
	border: none;
	/* No border */
	color: #4CAF50;
	/* Set the text color to green (or any color of your choice) */
	font-size: 14px;
	/* Set the font size to match other text */
	font-weight: 500;
	/* Font weight to match other text */
	cursor: pointer;
	/* Set cursor to pointer to show it's clickable */
	display: flex;
	align-items: center;
	gap: 8px;
	/* Space between icon and text */
	padding: 0;
	/* No padding to make it like plain text */
	text-decoration: none;
	/* Ensure no underline (if it's a link) */
	box-shadow: none;
	align-self: center;
}

.download-btn img {
	width: 14px;
	height: 20px;
	margin-left: 8px;
	/* Keep space between the text and the icon */
	color: #333;
}

.download-btn:hover {
	background: none;
	/* No background on hover */
	color: #4CAF50;
	/* Keep color same on hover */
	text-decoration: none;
	/* Ensure no underline on hover */
}

.download-btn:focus {
	outline: none;
	/* Remove outline when focused */
	box-shadow: none;
}

.cloudflare-download-button {
	display: flex;
	color: initial;
	align-items: center;
	margin-top: 20px;
}

.rightArrow {
	height: 12px;

}

.cdn-wrapper {
	display: flex;
	flex-direction: column;
	padding: 0px;
	background-color: inherit;
	border-radius: 8px;
	width: 100%;
	max-width: 600px;
	margin: 5px auto;
	font-family: "Roboto", arial, sans-serif;
	font-weight: 500;

	/* Ensure everything is left-aligned except headers */
	text-align: left;

	/* Responsive Design for smaller screens */
	@media (max-width: 768px) {
		max-width: 90%;
		/* Reduce max-width on tablets and smaller devices */
		margin: 10px;
	}

	@media (max-width: 480px) {
		max-width: 95%;
		/* Further reduce on small screens like mobile */
		padding: 15px;
	}
}

.cdn-header {
	font-size: 16px;
	font-weight: bold;
	margin: 10px;

	/* Center-align the header */
	text-align: center;

	/* Responsive adjustment */
	@media (max-width: 768px) {
		font-size: 14px;
		/* Slightly smaller font size on smaller devices */
		margin-bottom: 8px;
	}

	@media (max-width: 480px) {
		font-size: 12px;
		/* Reduce font size for very small screens */
		margin-bottom: 5px;
	}
}

/* Styling for the ordered list (ol) */
ol {
	margin: 0;
	padding-left: 10px;
	/* Indentation for ordered list */
	list-style-type: decimal;
	/* Ensure ordered list has numbers */
	/* General text color for the ordered list */

	/* Ensure everything in the ordered list is left-aligned */
	text-align: left;

	&.smallLetters{
		list-style-type: lower-alpha;
	}

	/* Responsive adjustment */
	@media (max-width: 768px) {
		padding-left: 15px;
		/* Slightly more indentation on medium screens */
	}

	@media (max-width: 480px) {
		padding-left: 20px;
		/* More indentation on smaller screens */
	}
}

.cdn-info {
	font-size: 16px;
	text-align: left;
	/* Left align the text here */

	/* Responsive adjustment */
	@media (max-width: 768px) {
		font-size: 13px;
		/* Slightly larger font on medium screens */
	}

	@media (max-width: 480px) {
		font-size: 14px;
		/* Increase font size for mobile */
	}
}

/* Styling for each list item (li) - Left-aligned text */
li {
	margin-bottom: 0px;
	/* Space between list items */
	text-align: left;
	/* Ensure list items are left-aligned */

	&:last-child {
		margin-bottom: 0;
		/* Remove margin on the last list item */
	}

	padding: 5px 0;

	/* Responsive adjustment */
	@media (max-width: 768px) {
		font-size: 13px;
		/* Increase font size on tablets */
		margin-bottom: 8px;
	}

	@media (max-width: 480px) {
		font-size: 14px;
		/* Increase font size on mobile */
		margin-bottom: 12px;
	}

	.bold-text {
		font-weight: bold;
		color: #4CAF50;
	}
}

/* Styling for the strong text with the icon */
strong {
	font-weight: 600;
	font-size: 14px;
	color: #000;
	display: inline;

	/* Responsive adjustment */
	@media (max-width: 768px) {
		font-size: 12px;
		/* Slightly smaller font size on tablets */
	}

	@media (max-width: 480px) {
		font-size: 12px;
		/* Keep the font small on mobile */
	}
}

/* Icon styling */
// img {
//   width: 14px;
//   height: 20px;
//   margin-left: 5px;

//   /* Responsive adjustment */
//   @media (max-width: 768px) {
//     width: 18px; /* Smaller icon size on tablets */
//     height: 18px;
//   }

//   @media (max-width: 480px) {
//     width: 16px; /* Smaller icon size on mobile */
//     height: 16px;
//   }
// }

/* Styling for API_KEY and Render Engine sections */
.apikey,
.renderengine {
	// margin-top: 15px;
	// margin-bottom: 15px;

	h6 {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 5px;

		/* Center-align the h6 headers */
		text-align: center;
	}

	ul {
		// padding-left: 20px;

		li {
			font-size: 16px;
			// margin-bottom: -14px;

			&:last-child {
				margin-bottom: 0;
				/* Remove margin on the last list item */
			}

			strong {
				font-weight: 600;
				color: #000;
			}
		}
	}
}

li.apiKeySpace {
	margin-bottom: 5px;
}

.rightArrow {
	height: 12px;

}

/* progressbar.scss */
.progressive-bar {
	text-align: center;
	padding-top: 20px;
	background-color: var(--white);
	font-family: "Roboto", arial, sans-serif;
	font-weight: 500;
	margin-top: 20px;

	.title {
		font-weight: 500;
		font-size: 24px;
		margin-bottom: 24px;
		text-shadow: #29394e;
	}

	.circles {
		display: flex;
		justify-content: space-between;
		/* Distribute circles evenly across the container */
		align-items: center;
		width: 624px;
		/* Set the fixed width of the circle container */
		margin: 0 auto;
		position: relative;
		gap: 1px;
		/* Ensures 1px gap between circles */
	}

	.circle {
		width: 38px;
		height: 38px;
		border: 2px solid #005EFF;
		border-radius: 50%;
		transition: border 0.3s;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		/* Centers the inner circle inside the outer circle */

		&::before {
			content: "";
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background-color: transparent;
			transition: background-color 0.3s ease;
		}

		&.active {
			border: 3px solid #005EFF;

			&::before {
				background-color: #005EFF;
			}
		}

		&.completed {
			border: 3px solid #005EFF;

			&::before {
				background-color: #005EFF;
			}
		}
	}

	.line {
		width: 40%;
		height: 2px;
		background-color: #29394e;
		margin: 0 1px;
	}
}

.getstarted {
	// width: 100vw;
	// // height: 80px;
	// border-bottom: 1px solid #29394e;
	// padding: 20px 0;

	padding: 24px 64px;
	border-bottom: 1px solid rgb(214, 214, 231);
	color: #29394e;
}

.header-wrapper {
	display: flex;
	align-items: center;
	gap: 9px;
}

.onboardingHeader {
	width: 250px;
}

/* styles/displaydomain.scss */

.form-container {
	text-align: center;
	margin: 30px auto;
	max-width: 700px;
	min-height: 70%;
	padding: 20px;
	background-color: var(--white);
	border-radius: 8px;
	/* Rounded corners for the form */
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	/* Light shadow to lift the form */
	display: flex;
	flex-direction: column;
	gap: 30px;
}

h6 {
	font-size: 15px;
	margin-bottom: 20px;
}

.form-field {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 55%;
	max-width: 600px;
	margin: 0 auto;
}

/* Error message for the general form (e.g., invalid submission) */
.error-message.general {
	color: red;
	font-size: 12px;
	text-align: right;
	margin-top: 15px;
	width: 44% !important;
}

/* Submit button styles */
// button {
// 	width: 43%;
// 	margin: 0 auto;
// 	padding: 10px;
// 	background-color: #005EFF;
// 	color: white;
// 	border: none;
// 	border-radius: 10px;
// 	cursor: pointer;
// 	font-size: 12px;
// 	transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

// 	&:focus {
// 		outline: none;
// 		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
// 		/* Green outline on focus */
// 	}

// 	&:disabled {
// 		background-color: #d6d6d6;
// 		cursor: not-allowed;
// 	}
// }

.form-field input:invalid {
	border-color: red;
}

button.cta.setup-btn {
	width: 45%;
	height: 45px;
	border: none;
	outline: none;
	width: 45%;
	height: 45px;
	box-shadow: none;
}

/* last.scss */
.last-card-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 70%;
	background-color: var(--white);
	//padding: 20px;
	//margin-top: 20px;
	font-family: "Roboto", arial, sans-serif;
	font-weight: 500;
}

.outer-card {
	background-color: var(--white);
	border-radius: 8px;
	width: 60%;
	max-width: 724px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	text-align: center;

	margin: 10px, auto;
}

.header {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 10px;
}

.completion-text {
	padding-top: 40px;
	font-size: 18px;
	margin-bottom: 20px;
}

.finish-button {
	background-color: #005EFF !important;
	color: white;
	padding: 10px 5px;
	border: none;
	border-radius: 10px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.finish-button:hover {
	background-color: #29394E !important;
}

.bot-configuration-wrapper i{
	font-size: 12px;
	margin-left: 5px;
	margin-right: 5px;
}

.navigation-buttons:not(:has(.prev-button)){
	justify-content: flex-end;
}

.navigation-buttons:not(:has(.next-button)) {
	justify-content: flex-start;
}

.domainError{
	color: red;
    position: relative;
    bottom: 10px;

}