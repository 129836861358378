/* Sidebar Container */
.innerSideBarContainer {
	background-color: #f8f9fa;
	border-right: 1px solid #ddd;
	height: 100vh;
	display: flex;
	flex-direction: column;
	font-family: Arial, sans-serif;
	transition: width 0.3s ease;
	overflow-x: hidden;
	width: 220px;
	padding: 0 15px;
	scrollbar-width: none;
	-ms-overflow-style: none;

	.inner-header {
		font-size: medium;
		display: flex;
		font-weight: 600;
		color: #29394e;
		padding: 20px 0;
		margin-bottom: 20px;
		border-bottom: 1px solid #ddd;
	}
}

.inner-lists {
	display: flex;
	flex-direction: column;
	padding: 15px;
	margin: 0;
	list-style: none;
	flex-grow: 1;
}

.sidebar-item {
	font-size: 15px;
	color: #555;
	padding: 5px;
	padding-right: 0px;
	padding-left: 0px;
	cursor: pointer;
	transition: all 0.1s ease;
}

.sidebar-item.active {
	font-weight: bold;
	color: #005eff;
	// --cb-ring-shadow: 0 0 #0000;
	// --cb-ring-offset-shadow: 0 0 #0000;
	// --cb-shadow: 0px 0px 0px 1px rgba(35, 38, 59, 0.05),
	//   0px 1px 3px 0px rgba(35, 38, 59, 0.15);
	// --cb-shadow-colored: 0px 0px 0px 1px var(--cb-shadow-color),
	//   0px 1px 3px 0px var(--cb-shadow-color);
	// box-shadow: var(--cb-ring-offset-shadow, 0 0 #0000),
	//   var(--cb-ring-shadow, 0 0 #0000), var(--cb-shadow);
	// --cb-gradient-to: #fcfcfd;
	// --cb-gradient-from: #fff;
	// --cb-gradient-to: hsla(0, 0%, 100%, 0);
	// --cb-gradient-stops: var(--cb-gradient-from), var(--cb-gradient-to);
	// background-image: linear-gradient(to bottom, var(--cb-gradient-stops));
}


.submenu {
	padding-left: 20px;
	margin-top: 5px;
}

.sidebar-item.active-blue .sidebar-item-content {
	color: #007bff;
	font-weight: bold;
}


.sidebar-item-content1 {
	color: #005eff;
}

.linkGroup {
	padding-left: 25px;
	margin-top: 15px;
}

.linkGroup li {
	position: relative;
	padding: 7px 0;
}

.linkGroup li::before {
	content: '';
	width: 2px;
	height: 100%;
	position: absolute;
	left: -20px;
	background-color: #c4c9cf;
	top: 0;
}

// .linkGroup li:first-child {
// 	padding-top: 10px;
// }

.linkGroup li:last-child {
	padding-bottom: 3px;
}

ul.searchNavigationList > li:not(:first-child) {
	margin-top: 20px;
}

.linkGroup li:has(.active)::before{
	background-color: #29394e;
}