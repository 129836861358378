//@import "common.scss";

.authLayoutWrapper {	
	.authLayoutInnerWrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		grid-area: content;
		overflow: auto;
		height: 100vh;
		width: 100%;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		&.successSignUp{
			align-items: center;
		}
		.authLayoutInnerContainer {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			padding-top: 3rem;

			&>* {
				margin-bottom: 1.5rem;
				// width: 365px;
			}

			.logoWrapper {
				display: flex;
				flex-direction: column;
				gap: 16px;
				align-items: center;
				margin-bottom: 16px;
				img {
					height: 32px;
				}
				h1{
					font-size: 25px;
					margin-bottom: 0;
				}
			}

			.authFormContainer {
				padding-bottom: 2rem;

				.authFormInnerWrapper {
					// background-color: rgb(255 255 255);
					// padding: 1.5rem;
					// border: 1px solid rgb(219, 223, 231);
					// border-radius: 8px;
					// -webkit-box-shadow: 0px 4px 16px rgba(99, 77, 255, .06);
					// box-shadow: 0 4px 16px #634dff0f;
					background: var(--white);
					border-radius: 13px;
					box-shadow: 0 2px 4px 0 rgba(0,0,0,.08), 0 0 6px 0 rgba(0,0,0,.02);
					padding: 24px;
					width: 436px;
					display: flex;
					flex-direction: column;
					gap: 16px;

					&.successSignUp{
						width: 540px;
					}

					.authHeaderWrapper {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						margin-bottom: 2rem;

						h3 {
							font-size: 1.25rem;
							line-height: 1.25;
							font-weight: 400;
						}
					}

					.or{
						.spacer{
							border-bottom: 1px solid var(--border-grey-light);
						}
					}

					.authFormWrapper {
						.input-group {
							.input-group-text {
								background-color: transparent;
								border-left: none;
								.material-icons-outlined {
									line-height: 1;
									font-size: 20px;
								}

								.showPasswordWrapper {
									display: flex;
									cursor: pointer;
								}
							}
							[type=password]{
								border-right: none;
							}
							.form-control {
								min-height: 45px;
								box-shadow: none;
								background-color: transparent;
							}							
						}

						.position-relative{
							.form-label{
								background: var(--white);
								border-radius: 8px;
								color: var(--text-light);
								font-size: 12px;
								font-weight: 500;
								left: 8px;
								line-height: 14px;
								padding: 0 6px;
								position: absolute;
								top: -10px;
								z-index: 9;
							}
						}

						form{
							display: flex;
							flex-direction: column;
							gap: 25px;

							.authCta{
								font-size: 16px;
								font-weight: 500;
								height: 55px;
								background: radial-gradient(163.33% 163.33% at 50% 100%,hsla(0,0%,100%,.45) 0,transparent 100%,hsla(0,0%,100%,0) 100%),var(--primary-color);
								background-blend-mode: overlay,normal;
								border: 1px solid var(--btn-border-color);
								box-shadow: inset 0 0 0 1px var(--btn-outline-color),0 3px 4px var(--btn-shadow-color);
								color: var(--always-white);
								width:100%;
							}
						}
					}

					.authFooterLinkWrapper {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}
}

.socialIconWrapper {
	display: flex;
	flex-flow: row nowrap;
	gap: 16px;
	.btn{
		flex: 1 0 calc(50% - 48px);
		i.logo{
			background-position: 50%;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 18px;
			min-width: 18px;
			&.google{
				background-image: url(../images/google-logo.svg);
			}
			&.microsoft{
				background-image: url(../images/microsoft-logo.svg);
			}
		}
		&.btn-secondary{
			color: var(--text-active);
		}
	}
}

.gsi-material-button {
	// -moz-user-select: none;
	// -webkit-user-select: none;
	// -ms-user-select: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: WHITE;
	background-image: none;
	border: 1px solid #dedede;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #1f1f1f;
	cursor: pointer;
	font-family: 'Roboto', arial, sans-serif;
	font-size: 14px;
	height: 40px;
	letter-spacing: 0.25px;
	outline: none;
	overflow: hidden;
	padding: 0 12px;
	position: relative;
	text-align: center;
	-webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
	transition: background-color .218s, border-color .218s, box-shadow .218s;
	vertical-align: middle;
	white-space: nowrap;
	width: auto;
	max-width: 400px;
	min-width: min-content;
  }
  
  .gsi-material-button .gsi-material-button-icon {
	height: 20px;
	margin-right: 12px;
	min-width: 20px;
	width: 20px;
  }
  
  .gsi-material-button .gsi-material-button-content-wrapper {
	-webkit-align-items: center;
	align-items: center;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	height: 100%;
	justify-content: center;
	position: relative;
	width: 100%;
  }
  
  .gsi-material-button .gsi-material-button-contents {
	-webkit-flex-grow: 0;
	flex-grow: 0;
	font-family: 'Roboto', arial, sans-serif;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
  }
  
  .gsi-material-button .gsi-material-button-state {
	-webkit-transition: opacity .218s;
	transition: opacity .218s;
	bottom: 0;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
  }
  
  .gsi-material-button:disabled {
	cursor: default;
	background-color: #ffffff61;
	border-color: #1f1f1f1f;
  }
  
  .gsi-material-button:disabled .gsi-material-button-contents {
	opacity: 38%;
  }
  
  .gsi-material-button:disabled .gsi-material-button-icon {
	opacity: 38%;
  }
  
  .gsi-material-button:not(:disabled):active .gsi-material-button-state, 
  .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
	background-color: #303030;
	opacity: 12%;
  }
  
  .gsi-material-button:not(:disabled):hover {
	-webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  }
  
  .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
	background-color: #303030;
	opacity: 8%;
  }