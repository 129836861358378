// .dateRangePickerOuter{
// 	position: relative;
// 	#dateRange{
// 		padding: 8px 25px;
// 		font-size: 14px;
// 		background-color: #fff;
// 		color: #333;
// 		border: 1px solid #ccc;
// 		border-radius: 5px;
// 		cursor: pointer;
// 		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
// 		width: 100%;
// 		.dateRangePicketInner{
// 			position: absolute;
// 			top: 100%;
// 			left: 0%;
// 			z-index: 999;
// 			display: flex;
// 			flex-direction: column;
// 			max-height: 400px;
// 			width: 100%;
// 			padding: 10px;
// 			background-color: #fff;
// 			border-radius: 8px;
// 			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// 			overflow-y: auto;
// 			.bd-button{
// 				font-size: 13px;
// 				font-weight: 400;
// 				height: 30px;
// 				padding: 10px 15px;
// 			}
// 		}
// 	}
// }
.dateRangePickerOuter{
	width: 580px;
	position: relative;
	#dateRange{
		padding: 5px 5px;
		font-size: 14px;
		background-color: #fff;
		color: #333;
		border: 1px solid #ccc;
		border-radius: 5px;
		cursor: pointer;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
		width: 100%;
		max-width: 235px;
		display: block;
		margin-left: auto;
	}
	.dateRangePicketInner{
		position: absolute;
		top: 100%;
		left: 0%;
		z-index: 999;
		display: flex;
		flex-direction: column;
		max-height: 400px;
		width: 100%;
		padding: 10px;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		overflow-y: auto;
		.bd-button{
			font-size: 13px;
			font-weight: 400;
			height: 30px;
			padding: 10px 15px;
		}
	}
}